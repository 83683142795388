export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1, user-scalable=no"},{"charset":"utf-16"}],"link":[{"rel":"preload","as":"font","type":"font/otf","crossorigin":"anonymous","href":"/fonts/Fractul/Fractul-Regular.otf"},{"rel":"preload","as":"font","type":"font/otf","crossorigin":"anonymous","href":"/fonts/Fractul/Fractul-Medium.otf"},{"rel":"preload","as":"font","type":"font/woff2","crossorigin":"anonymous","href":"/fonts/Basis-Grotesque-Pro/BasisGrotesquePro-Regular.woff2"},{"rel":"preload","as":"font","type":"font/woff2","crossorigin":"anonymous","href":"/fonts/Basis-Grotesque-Pro/BasisGrotesquePro-Medium.woff2"}],"style":[{"children":"@font-face{font-family:\"Fractul\";font-weight:400;font-style:normal;font-display:optional;src:url('/fonts/Fractul/Fractul-Regular.otf') format('opentype');}@font-face{font-family:\"Fractul\";font-weight:500;font-style:normal;font-display:optional;src:url('/fonts/Fractul/Fractul-Medium.otf') format('opentype');}@font-face{font-family:\"Basis Grotesque Pro\";font-weight:400;font-style:normal;font-display:optional;src:url('/fonts/Basis-Grotesque-Pro/BasisGrotesquePro-Regular.woff2') format('woff2');}@font-face{font-family:\"Basis Grotesque Pro\";font-weight:500;font-style:normal;font-display:optional;src:url('/fonts/Basis-Grotesque-Pro/BasisGrotesquePro-Medium.woff2') format('woff2');}.fractul{font-family:\"Fractul\";}.fractul-medium{font-family:\"Fractul\";}.basis-gro-pro{font-family:\"Basis Grotesque Pro\";}.basis-gro-pro-medium{font-family:\"Basis Grotesque Pro\";}"}],"script":[],"noscript":[],"charset":"utf-16","viewport":"width=device-width, initial-scale=1, user-scalable=no","title":"Scratchpad Studio"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"