import { default as aboutyQ6yg1weDpMeta } from "/projects/scratchpad-studio/frontend/pages/about.vue?macro=true";
import { default as contact0k1rGO0gMTMeta } from "/projects/scratchpad-studio/frontend/pages/contact.vue?macro=true";
import { default as indexLSlBlxJq27Meta } from "/projects/scratchpad-studio/frontend/pages/index.vue?macro=true";
import { default as _91slug_93IpvpwJWjdWMeta } from "/projects/scratchpad-studio/frontend/pages/work/[slug].vue?macro=true";
import { default as indexrVCFljbStDMeta } from "/projects/scratchpad-studio/frontend/pages/work/index.vue?macro=true";
export default [
  {
    name: aboutyQ6yg1weDpMeta?.name ?? "about",
    path: aboutyQ6yg1weDpMeta?.path ?? "/about",
    meta: aboutyQ6yg1weDpMeta || {},
    alias: aboutyQ6yg1weDpMeta?.alias || [],
    redirect: aboutyQ6yg1weDpMeta?.redirect || undefined,
    component: () => import("/projects/scratchpad-studio/frontend/pages/about.vue").then(m => m.default || m)
  },
  {
    name: contact0k1rGO0gMTMeta?.name ?? "contact",
    path: contact0k1rGO0gMTMeta?.path ?? "/contact",
    meta: contact0k1rGO0gMTMeta || {},
    alias: contact0k1rGO0gMTMeta?.alias || [],
    redirect: contact0k1rGO0gMTMeta?.redirect || undefined,
    component: () => import("/projects/scratchpad-studio/frontend/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: indexLSlBlxJq27Meta?.name ?? "index",
    path: indexLSlBlxJq27Meta?.path ?? "/",
    meta: indexLSlBlxJq27Meta || {},
    alias: indexLSlBlxJq27Meta?.alias || [],
    redirect: indexLSlBlxJq27Meta?.redirect || undefined,
    component: () => import("/projects/scratchpad-studio/frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93IpvpwJWjdWMeta?.name ?? "work-slug",
    path: _91slug_93IpvpwJWjdWMeta?.path ?? "/work/:slug",
    meta: _91slug_93IpvpwJWjdWMeta || {},
    alias: _91slug_93IpvpwJWjdWMeta?.alias || [],
    redirect: _91slug_93IpvpwJWjdWMeta?.redirect || undefined,
    component: () => import("/projects/scratchpad-studio/frontend/pages/work/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexrVCFljbStDMeta?.name ?? "work",
    path: indexrVCFljbStDMeta?.path ?? "/work",
    meta: indexrVCFljbStDMeta || {},
    alias: indexrVCFljbStDMeta?.alias || [],
    redirect: indexrVCFljbStDMeta?.redirect || undefined,
    component: () => import("/projects/scratchpad-studio/frontend/pages/work/index.vue").then(m => m.default || m)
  }
]